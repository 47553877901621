/**
 * @file
 *   Adds main javascript functionality to the SITENAME website
 *
 * @author
 *   Thomas Torfs - Savant Media - www.savantmedia.be
 *
 */

(function ($) {
  $(document).ready(function(e) {

    /* Medical pages - Table of contents */
    $('#toc').toc({
      'container': '.toc-target'
    });

    /* Medical pages - Pinned sidebar */
    // $('aside.page_aside > .content').pin({
    //   activeClass: 'sticky',
    //   minWidth: 912,
    //   containerSelector: '.block-system > .content'
    // });

    $('#sidebar-videos .video > a').fancybox({
      openEffect  : 'none',
      closeEffect : 'none',
      scrolling   : 'no',
      helpers : {
        media : {}
      }
    });

  });
})(jQuery);
